.storeimg {
    display: block;
    width: 100%;

    figure {
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            padding-top: 61%;
            display: block;
        }

        img {
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            height: auto;
            width: auto;
            margin: 0 auto;
            
        }
    }
}


.discountmobilesection {
    margin: 10px auto;

    img {
        height: auto;
    }
}

.discountmobilesection {
    padding: 8px 10px 3px;
    background-color: #F5F5F5;
    overflow: hidden;
    margin-top: 6px;

    .discounslider {
        overflow: auto;
        white-space: nowrap;
        display: flex;
        margin: 0px -5px;

        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
            display: none;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
    }

    .discountimg {
        img {
            height: auto;
            display: block;
            min-width: 380px;

        }

    }

}