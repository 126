.recommendedsection{
    padding: 20px 0px 35px;
    .recommendedinner{
        padding: 0px 10px;
    }
    .recommendedheading{
        margin-bottom: 30px;

        .heading {
        font-size: 38px;
        line-height: 40px;
        color: #333333;
        font-family: "Abhay-Libre Bold";
        text-transform: capitalize;
        margin: 0px;
        }

        p {
            font-size: 15px;
            line-height: 18px;
            font-family: "pangramregular";
            color: #515151;
            letter-spacing: 0.02em;
            font-weight: normal;
            margin: 0px;
        }
    }

    .recommendedinner {
        padding: 0px 30px;
    }

    .recomcard {
        display: block;
        overflow: hidden;

        .recomimg {
            position: relative;
            overflow: hidden;

            figure {
                position: relative;
                overflow: hidden;
                border-radius: 3px;
                background: #f5f5f5;

                &:before {
                    content: "";
                    display: block;
                    padding-top: 79%;
                }
            }

            .imgreco {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                transition: 0.3s ease-in-out;
            }
        }

        .recomimgbig {
            figure {
                border-radius: 0px;
                &:before {
                    padding-top: 83%;
                }
            }

        }

        .recombtnover {
            position: absolute;
            left: 6px;
            top: 6px;
            padding: 0px 10px 0;
            text-transform: capitalize;
            border-radius: 4px;
            display: block;
            // display: none;

            img {
                width: auto !important;
                height: auto !important;
                transform: none !important;
            }

            label {
                font-size: 13px;
                line-height: 14px;
                font-family: "pangrammedium";
                right: 12px;
                color: #fff;
                top: 6px;
                position: absolute;

            }
        }

        .recomtext {
            padding: 6px 0px 15px;

            p {
                font-size: 17px;
                line-height: 20px;
                color: #333333;
                letter-spacing: 0.02em;
                margin: 0 0 3px;
                font-family: "pangrammedium";
                text-transform: capitalize;
            }
        }

        &.recomcardbig {
            .recomtext {
                p {
                    color: #333333 !important;
                }
            }
        }

        &:hover {
            .recomimg {
                img {
                    transform: scale(1.04);
                }
            }

            .recomtext {
                p {
                    color: #E27A34;
                }
            }

        }
    }
}